.dashboard-metrics-count {
  font-size: 1.2em;
  letter-spacing: 1.5px;
  padding: 5px !important;
}

.refresh-link {
  opacity: 0.6;
  margin-bottom: -3px;
}

.refresh-link:hover {
  opacity: 1;
  cursor: pointer;
}