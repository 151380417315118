.ribbon-container {
  position: relative;
}

.ribbon {
  width: 140px;
  height: 140px;
  overflow: hidden;
  position: absolute;
  z-index: 1000;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #007BBB;
}
.ribbon span {
  position: absolute;
  display: block;
  background-color: #007BBB;
  width: 225px;
  padding: 15px 0;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.ribbon-topbar {
  width: 140px;
  height: 80px;
  overflow : hidden;
  position: absolute;
  z-index: 1000;
}
.ribbon-topbar::before,
.ribbon-topbar::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #007BBB;
}
.ribbon-topbar span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 14px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-left-topbar {
  top: -20px;
  left: 150px;
}
.ribbon-top-left-topbar::before,
.ribbon-top-left-topbar::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left-topbar::before {
  top: 0;
  right: 0;
}
.ribbon-top-left-topbar::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left-topbar span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}