// change the theme
$theme-colors: (
  "primary": #007BBB,
  "danger": #AA483E,
  "warning": #FFAA3B,
  "info": #7FCDF2,
  "success": #1AA17A,
  "secondary": #FF783B,
  "dark": #4F5660,
  "light": #e0e0e0,
  "warning-light": #fcb456,
  "success-light": #20c395,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';