.column {
  display: flex;
  justify-content: center;
  width: 100%
}

.active-radar-pill {
  font-size: 0.8rem;
  font-weight: 400;
}

.tip { 
  text-align: center;
  color: #888;
  font-size: 0.9em;
  margin: 10px 0px;
}