.btn.btn-custom-hollow {
    font-size: 1.4em;
    color: #757575;
    padding: 2px 6px 0px 6px;
}

.btn.btn-custom-hollow:hover {
    background-color: #E0E0E0;
}

.action-button {
    text-align: center;
    display: block;
}

.edited-tag {
    max-width: 60px;
    margin-right: auto;
    align-self: top;
}

.badge-custom-edited {
    margin-left: 0.5rem;
    font-size: 0.7rem;
    letter-spacing: 0.6px;
}