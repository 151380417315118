
.column{
    display: flex;
    justify-content: center;
    width: 100% 
}
.column-left {
  display: flex;
  justify-content: flex-start;
  width: 100% ;
  white-space: pre-wrap;
}
.paw-group-pill {
    font-size: 0.8rem;
    font-weight: 400;
    flex-wrap: wrap;
    border-radius: 2.5rem;
}