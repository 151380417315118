table.rm-key-value-table {
  margin-bottom: 0;
}

table.rm-key-value-table>tbody>tr:first-child>th,
table.rm-key-value-table>tbody>tr:first-child>td {
  border-top: none !important;
}

table.rm-key-value-table th {
  text-align: right;
}

table.rm-key-value-table th,
table.rm-key-value-table td {
  padding: 0.6rem;
}

.rm-notes-filter-container {
  padding: 0 15px;
  width: 100%;
}

.rm-notes-filter-label {
  padding: 8px 15px;
}

.rm-notes-filter-select {
  width: 40%;
}

.rm-notes {
  padding: 10px 15px;
  padding-bottom: 0;
}

.rm-switch-note-container {
  padding-bottom: 8px;
  padding-right: 20px;
}

.rm-switch-note {
  font-size: 0.9em;
  border-bottom: 1px solid lightgray;
}

.clipped {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rm-switch-note-author {
  color: rgba(93, 91, 91, 0.8);
  font-size: 0.8em;
}

.rm-switch-notes-end {
  color: rgba(93, 91, 91, 0.8);
  font-size: 0.8em;
  margin: 0 auto;
}

.rm-horizontal-center {
  display: flex;
  justify-content: center;
}

.rm-xs-top-label {
  font-size: 0.6em;
  font-style: italic;
  color: rgba(0, 0, 0, 0.7);
}

.rm-tiny-top-label {
  font-size: 0.7em;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}

.rm-top-label {
  font-size: 0.75em;
  font-style: italic;
  color: rgba(0, 0, 0, 0.7);
}

.rm-small-top-label {
  font-size: 1em;
  font-style: italic;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 900;
}

.ticket-modal {
  width: 40%;
  max-width: none !important;
}

.group-name {
  margin-bottom: 15px;
  font-size: 1.4em;
  color: #585757;
}

.group-name-clickable {
  cursor: pointer;
  color: #008df1;
}

.group-name-clickable:hover {
  text-decoration: underline;
}

.rm-blue-container {
  color: #585757;
  padding: 5px 0px;
  border: 1px solid #d6d8db;
  border-radius: .25rem;
  background-color: #f0f8fd;
}

.rm-disabled-container {
  background-color: #f7f7f7;
}

.source-name {
  padding: 7px 20px;
  cursor: pointer;
  font-size: 1.1em;
}

.source-name-selected,
.source-name:hover {
  background-color: #c6e4f9;
}

.source-name-selected {
  color: #008df1;
}

.source-name-selected:hover {
  cursor: unset;
}

.source-ed-it-container {
  display: inline;
  float: right;
}

.selected-switch-right-arrow {
  width: 0;
  height: 0;
  border-top: 1.1em solid transparent;
  border-bottom: 1.1em solid transparent;
  border-left: 1.1em solid #c6e4f9;
  margin-top: -0.35em;
  margin-right: -2.3em;
  float: right;
  display: inline;
}

.source-clickable-icon {
  cursor: pointer;
  display: inline;
  margin: 0 7px;
}

.source-clickable-icon.drag-icon {
  cursor: move;
}


.btn-source-add {
  background-color: #f0f8fd;
  font-size: 1.4em;
  color: #008df1;
}

.btn-source-add:hover {
  background-color: #c6e4f9;
}

.btn-source-add:active {
  color: #008df1;
}


.btn-add-target-option,
.btn-edit-target-option {
  margin-left: 5px;
  border-color: #068ff1;
  color: #068ff1;
  background-color: #f0f8fd;
  font-size: 12px;
}

.btn-add-target-option:hover,
.btn-edit-target-option:hover {
  color: #ffffff;
  background-color: #068ff1;
}

.btn-outline-danger.delete-target-option {
  font-size: 12px;
  margin-left: 5px;
}

.margin-left-22 {
  margin-left: 22px;
}

.grouped-targets-container {
  color: #008df1;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.target-name {
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.codex-version-display-bar {
  background-color: #007BBB;
  letter-spacing: 1.2px;
  border: 1px solid #007BBB;
  border-radius: 3px;
  margin-bottom: 10px;
  color: white !important;
  text-transform: capitalize;
  padding: 8px;
  /* -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.39);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.39);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.39); */
  text-align: center;
}

.template-type-display {
  border: 1px solid #008df1;
  border-radius: 4px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: capitalize;
  padding: 10px;
  letter-spacing: 1px
}

.dynamic-switch-option-type-label {
  font-size: 1.2em;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  border-style: dashed;
  border-width: 0px 0px 0.1em 0px;
  border-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.check-field{
  display: inline;
}

.filtered-switch-items{
  display: inline-flex;
}

.days-supply-row input {
  border: none;
  width: auto;
}

.days-supply-row input::-webkit-outer-spin-button,
.days-supply-row input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.days-supply-row input:focus-visible{
  outline-width: 0;
}

.days-supply-box{
width: auto;
height: auto;
padding: 0px;
padding-right: 12px;
}

.dso-pharmacist-review{
  margin-top: 10px;
}

.rm-required-field::after {
  color: red;
  content: ' *';
}

.switch-lookup-diff-modal-container .spinner {
  margin-top: 1em;
}

.diff-fields-container .cat-old-value {
  background-color: #fddddd;
  padding: 0px 6px;
  text-decoration: line-through;
  margin-right: 1em;
}

.diff-fields-container .cat-new-value {
  background-color: #e3f9dd;
  padding: 0px 6px;
}

.diff-fields-container .field-diff {
  min-height: 1em;
}

.diff-fields-container .row {
  margin-bottom: 1em;
}

.diff-fields-container .field-title {
  text-decoration: underline;
}

.diff-fields-container .field-title,
.diff-fields-container .field-value {
  color: #000000;
  font-size: 0.9em;
}

.target-option-container {
  min-height: 20px;
  padding: 0px 20px;
  background-color: #ffffff;
  /* border: 1px solid #e3e3e3; */
  border-radius: 4px;
}

.target-drug-group {
  min-height: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.drug-group-header {
  background: #E0E0E0;
  width: 734px;
  margin-left: -20px;
  margin-top: -20px;
  padding: 7px;
}

.rm-accordion {
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}

.rm-accordion-item {
  border-bottom: solid 1px #e3e3e3;
  padding: 1em;
}

.rm-accordion-item .rm-accordion-item-title {
  font-size: 1em;
}

.rm-accordion-item .rm-accordion-item-title:after {
  float: right;
  content: "\203A";
}

.rm-accordion-item.rm-accordion-item-expanded .rm-accordion-item-title {
  margin-bottom: 0.75em !important;
}

.rm-accordion-item.rm-accordion-item-expanded .rm-accordion-item-title:after {
  -moz-transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.divider {
  border-top: 1px dashed #E0E0E0;
  padding-bottom: 10px;
  margin-top: 2px;
}

.react-switch {
  display: block !important;
}

.display-inline {
  display: inline;
}

.center-badge {
  vertical-align: super;
}

.limit-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.notfound .notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 140px;
  height: 140px;
  background-image: url('../images/not-found.png');
  background-size: cover;
}

.notfound .notfound-404:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
  -ms-transform: scale(2.4);
  transform: scale(2.4);
  border-radius: 50%;
  background-color: #f2f5f8;
  z-index: -1;
}

.notfound h1 {
  font-family: 'Nunito', sans-serif;
  font-size: 65px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}

.notfound h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  color: #151723;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    width: 110px;
    height: 110px;
  }

  .notfound {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }
}

.align-right {
  margin-left: auto;
}

.fab-container {
  display: flex;
  flex-direction: row;

  position: fixed;
  right: 2em;
  bottom: 2em;

  max-height: 52px;
  
  /* border-radius: 50%; */
  box-shadow: 0 3px 6px lightgrey;
  place-items: center;
  margin: 8px 0;
  font-size: 15px;
  padding: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.rm-form-switch input.form-check-input {
  width: 3em;
  height: 1.5em;
}

.form-switch.rm-switch-negative input.form-check-input:checked {
  background-color: darkorange;
}