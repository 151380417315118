.rm-sidebar {
  font-size: 1em;
  border-radius: 4px; 
  padding: 15px 0;
  min-height: 35vh;
}

.rm-sidebar-subsection {
  font-size: 0.85em;
  padding-left: 1em;
}

.rm-nav-link {
  color: #ffffffbf;
  font-weight: 400;
  padding: 4px 0px 4px 14px;
  cursor: pointer;
}

.rm-nav-link .rm-nav-link-title {
  border-bottom: 1px solid transparent;
}

.rm-nav-link.rm-nav-parent .rm-nav-link-title:after {
  content: "\203A";
  float: right;
  margin-right: 0.75em;
  font-size: 1.2em;
}

.rm-nav-link.rm-nav-parent.rm-root-has-child-active .rm-nav-link-title:after
{
  -moz-transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  transform: rotateZ(90deg); 
}

.rm-sidebar-subsection .rm-nav-link {
  padding-top: 4px;
  padding-bottom: 4px;
}

.rm-nav-link:hover {
  color: #ffffff;
  background-color: #80808026;
}

.rm-nav-link-active,
.rm-root-has-child-active {
  color: #ffffff;
  font-weight: 600;
}

.rm-nav-link-active .rm-nav-link-title,
.rm-root-has-child-active .rm-nav-link-title {
  border-bottom: 1px solid white;
}

.rm-hidden {
  display: none;
}