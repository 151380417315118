.table-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 10px;
  margin-top: 10px;
  max-height: 60px;
}

.table-header-div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.table-header-title {
  margin-right: 5px;
}

.customBadge {
  align-self: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80px
}

.table-horizontal-scroll {
  max-width: 100%;
  overflow-x: auto;
}

.table-column-text-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
}

.table-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}