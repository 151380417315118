.drug-option-box {
  margin-bottom: 10px;
  background-color: #f5f3f3;
  padding-top: 10px;
}

.drug-group-box {
  margin-bottom: 10px;
  background-color: #F1F1F1;
}

.drug-title {
  font-weight: 600;
}

.arrow {
  display: none !important;
}

.alternative-label {
  color: rgb(98, 98, 98);
  margin-right: 2px;
}

.tooltip-inner {
  max-width: 300px;
  background-color: #35282aaa;
}

.drug-title-link-container {
  display: flex;
  align-items: flex-end;
  padding-top: 5px;
  font-size: 0.9rem;
}

.multiple-targets {
  background-color: #b9b2b2;
  border-radius: 5px;
  margin-bottom: 10px;
}

.collapsed-alternatives {
  padding-left: 10px;
}

.link-icon,
.icon-displacement-fix {
  position: relative;
  bottom: 2px;
}

.link-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-wrap;
}

.drug-option-title-badge {
  font-size: 0.8rem;
  font-weight: 400;
}

.dsg-inactive-filter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.main-button-link {
  color: rgb(144, 144, 144);
  font-weight: 100;
  font-style: normal;
}

.main-button-link:hover {
  color: rgb(144, 144, 144);
}

.delete-link {
  color: #dc3545;
}

.delete-link:hover {
  color: #ab2835;
}

.card-body-padding-override {
  padding: 1rem;
}

.dynamic-switch-group-name {
  padding: 1.5rem;
}

.group-name {
  margin-bottom: 0;
}

.dynamic-switch-options-list {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.dso-sort-class {
  flex-direction: column-reverse;
}

.dso-single-option-type {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.list-controls {
  padding-bottom: 0.5rem;
}

.dropdown-custom-button {
  text-transform: capitalize;
  background-color: white;
  color: black;
  min-width: 100px;
  border-color: black;
}

.space-pre-wrap {
  white-space: pre-wrap;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

#drug-group-is-active, #drug-option-form-is-enabled-for-fax {
  margin-top: revert;
}