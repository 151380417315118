.boxed-div-center {
   padding: 5px;
   text-align: center;
}

.entity-font {
   font-size: 0.8em;
}

.padded-row {
   padding: 12px;
}

.unclaim-callgroup-btn {
   width: 100%;
   color: white;
}

.unassign-callgroup-btn {
   width: 100%;
   color: white;
}

.btn-text-white {
   color: white;
}

.content-centered-col {
   text-align: center;
}

.content-align-start {
   text-align: start;
}

.rm-notes-activity {
   padding-top: 10px;
   overflow-y: auto;
   max-height: 350px;
   overflow-x: hidden;
   padding-left: 2px;
}

.small-label-notes {
   display: flex;
   letter-spacing: 1px;
   color: rgba(123, 110, 110, 0.8);
   border-bottom: 1px solid rgba(0, 0, 0, .1);
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   margin: auto;
}

.owner-alert-box {
   margin-bottom: 5px;
}

.note-action-btns {
   padding: 2px 0.5px;
}

.note-action-btns:focus {
   outline: none;
   box-shadow: none;
}

.note-action-btns:disabled {
   border-color: white;
   cursor: not-allowed;
   pointer-events: all !important;
}

.notes-container {
   padding-left: 0px;
   padding-right: 0px;
}

.create-note-btn:focus {
   outline: none;
   box-shadow: none;
}

.create-note-btn:disabled {
   border-color: white;
   cursor: not-allowed;
   pointer-events: all !important;
}

.aw-refresh-link {
   opacity: 1;
   font-size: 2em;
   margin-top: -10px;
 }
 
 .aw-refresh-link:hover {
   opacity: 0.6;
   cursor: pointer;
 }

 .aw-latest-callgroups-toggle {
   display: inline;
   padding-right: 5px;
   margin-top: 2px;
 }

 .form-switch .form-check-input:checked {
   background-color: mediumseagreen;
 }

 .link-text {
   text-decoration: underline;
   cursor: pointer;
 }