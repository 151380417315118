
html, body, #root {
    min-height: 100% !important;
    height: 100%;
}

body {
  margin: 0;
  padding: 10px;
  font-family: 'Lato', sans-serif;
  position: relative;
  background-color: white;
}

a.nullify-style:hover {
  text-decoration: none;
}

.huge-top-spacer {
  margin-top: 100px;
}

.no-margin {
  margin: 0;
}

.big-top-spacer {
  margin-top: 50px;
}

.top-spacer {
  margin-top: 20px;
}

.small-top-spacer {
  margin-top: 10px;
}

.small-top-spacer-important {
  margin-top: 10px !important;
}

.tiny-top-spacer {
  margin-top: 5px;
}

.bottom-spacer {
  margin-bottom: 20px;
}

.small-bottom-spacer {
  margin-bottom: 10px;
}

.tiny-bottom-spacer {
  margin-bottom: 5px;
}

.left-spacer {
  margin-left: 20px;
}

.tiny-right-spacer {
  margin-right: 5px;
}

.text-align-center {
  text-align: center;
}

.small-label {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(123, 110, 110, 0.8);
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.text-capitalize {
  text-transform: capitalize;
}

.right-border-solid {
  border-right: 1px solid lightgray;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

footer {
  background-color: #CFD8DC;
  border-top: 1px solid #B0BEC5;
  padding: 10px;
  min-height: 59px;
  z-index: 10;
}

.position-sticky-bottom {
  position: sticky;
  bottom: 0; 
}

footer.position-sticky {
  z-index: 1030;
}

footer.boosted-footer-z-index {
  z-index: 1031;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.width-max {
  width: 100%;
}

.react-datetime-picker__inputGroup{
  width: 100%;
}

.react-datetime-picker__wrapper{
  width: 100% !important;
  border: 1px solid #ced4da;
  padding: .375rem .75rem;
  border-radius:.25rem;
}

.react-datetime-picker {
  width: 100% !important; 
}

.highlight {
  background-color: #fff700;
}

/* RM New Colors */

/* Primary Colors */
.rm-white {
  color: #ffffffbf;
}
.rm-black {
  color: #19191B;
}

.rm-orange {
  background-color: #FF783B !important;
}

.rm-blue {
  background-color: #007BBB;
}

.rm-blue-light {
  background-color: #7FCDF2;
}

.rm-gray {
  background-color: #4F5660;
}

/* Color Variations */
.rm-orange-light {
  background-color: #FFC9B0;
}

.rm-blue-dark {
  background-color: #3E5795;
}

.rm-blue-very-light {
  background-color: #DDF4FF;
}

.rm-gray-light {
  background-color: #F1F1F1;
}

.rm-orange-dark {
  background-color: #B84D1D;
}

.rm-blue-very-dark {
  background-color: #252F3C;
}

.rm-gray-dark {
  background-color: #252F3C;
}

.rm-purple {
  background-color: #ff00fc !important;
}

/* Status Colors */
.rm-positive {
  background-color: #1AA17A;
}

.rm-warning {
  background-color: #FFAA3B;
}

.rm-danger {
  background-color: #AA483E;
}

.boxed-div {
  padding: 8px;
}

.boxed-div-blue {
  padding: 8px;
  margin-bottom: 1.2rem;
  box-shadow: 0px 0px 10px 3px #706d6d5c;
  border-radius: 0.2rem;
}

.create-note-btn {
  padding: 4px 0px;
}

.notes-box {
  height: auto;
  max-height: 350px;
}

.react-date-picker-modal input {
  width: 100%;
}

.client-selector__input {
  color: transparent;
}

.entity-heading{
  text-align: center;
  color: #007BBB;
  background-color: transparent;
  border-bottom: 1px solid #007BBB;
  padding: 0.15rem 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: .5rem;
}

.offcanvas-body {
  padding-bottom: 0;
}

.offcanvas-header {
  height: 5vh;
}

.switch-timeline-action-btns > .btn {
  margin-right: 2px;
}

.modal-header {
  cursor: move;
}

.disabled-button-on-light-bg {
  border-color: #acadac !important; 
  color: #acadac !important;
}

.enabled-button-on-light-bg {
  border-color: black !important; 
  color: black !important;
}