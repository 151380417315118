.container {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
    padding: 10px;
    min-height: 85%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.full-container {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
    padding: 10px;
}
.progress-container {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
    min-height: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    flex-direction: column;
}
.next-drug-group-container {
    min-height: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.progress-bar {
    align-self: center;
    text-align: center;
}
.progress-bar-color {
    color: #1aa17a;
}
.card-header {
    background-color: white;
    letter-spacing: 2px;
    border-style: none;
}
.reopen-button {
    margin-left: 10px;
    margin-right: 10px;
}
.auto-review-button {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 10px;
}
.button {
    box-shadow: 2px 2px 10px grey;
}