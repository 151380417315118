.rm-timeline {
  margin: 10px 0 !important;
}
.grouped-state-title {
  padding: 0 !important;
  float: right !important;
  font-weight: bold !important;
}
.vertical-timeline-element {
  margin: 1em 0 !important
}
