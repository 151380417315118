
.custom-dd-position .dropdown-menu {
  right: 0;
  left: auto;
}

.account-nav-dropdown {
  font-size: 1.3em;
  margin-right: 2px;
}

.sidebar-menu-btn {
  font-size: 2em;
  color: white;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}