.form-section {
    margin: 0.5rem;
    background-color: rgba(204, 204, 204, 0.1);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5)
}
.form-card-title{
    font-size: medium;
}

.gis{
    font-size: medium;
    font-weight: 800;
    text-align:center;
    margin-top: 3%;
}

.field-name{
    font-size: medium;
    font-weight: 800;
    margin: 0 1rem;
    display: inline-block;
    width: fit-content;
}   

.not-detected{
    display: inline-block;
    margin-left: auto;
    margin-right: 2rem;
    color: red;
    width: fit-content;
}

.low-confidence{
    color: rgb(231, 91, 91)

}
.med-confidence{
    color: rgb(226, 226, 89)
}
.high-confidence{
    color : rgb(97, 224, 97)
}
.confidence{
    display: inline-block;
    margin-left: auto;
    margin-right: 2rem;
    width: fit-content;
    

}
.edit-field{
    width: 100%;
}

.field-val{
    margin-left: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    /* border: 2px solid orange; */
    
}
.field-option{
    margin-left: 1.5rem;

}

.multiple-options{
    /* border: 2px solid orange; */
    margin: 0.5rem 0;
}
.field-val-text{
    margin: 0.5rem;

    /* border: 2px solid orange; */
}
.other-option{
    margin-left: 1.25rem;
    
}
.field-val-text-other{
    width: 10%;
    height: 10%;
    display: inline-block;
    margin-left: 0.5rem;
    padding: 0px;
    text-align: center;

}