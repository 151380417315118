.badge-style {
  margin-right: 10px;
  font-size: large;
  padding-left: 20px;
  padding-right: 20px;
}

.badge-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title {
  font-size: x-large;
  font-weight: 800;
  color: rgb(241,158,57);
  padding-right: 10px;
}

.underlay {
  background-color: rgb(246,246,246);
  padding: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.loading {
  font-size: large;
  color: rgb(107, 78, 42);
}
