.paw-display-bar {
  background-color: #007BBB; 
  letter-spacing: 1.2px; 
  border: 1px solid #007BBB;
  border-radius: 3px;
  margin-bottom: 16px;
  color: white; 
  text-transform: capitalize;
  padding: 8px 16px; 
  text-align: center;
}
.paw-info-bar {
  letter-spacing: 1.2px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 15px;
}

.paw-group-stats-container {
  letter-spacing: 1.1px;
  justify-content: space-between;
}
.paw-group-stats-item {
  /* border-bottom: 2px #ff783b solid;
  padding-bottom: 1px; */
}
.paw-group-stats-icon {
  font-size: 1.1rem;
  margin-top: -0.2rem;
}
.paw-group-stats-heading {
  color: #ffffff;
}
.paw-group-stats-value {
  color: #ffff;
  font-weight: bold;
}

.card-body {
  padding-top: 0px;
}
.card-shadow {
  box-shadow: 0 0px 10px rgba(0,0,0,.2);
  color: black;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
.card-shadow .title {
  text-align: left;
  color: #8e8e8e;
  font-size: 1em;
  font-weight: 900;
  margin-bottom: 0;
}
.card-shadow .text {
  color: #007BBB;
  font-size: 0.9em;
  font-weight: 900;
}
div.medicine-name {
  margin: 10px 0 2px;
}
div.switch-sec-heading{
  padding-left: 0px;
  margin: 20px 0px 20px 0px;
}
.alert {
  margin-bottom: 0px;
}
.border-div {
  border: 1px solid #bab8b8b8;
  border-radius: 5px;
  padding: 10px;
}
.bage-text {
  font-size: 0.8rem;
  font-weight: 400
}