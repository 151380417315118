.login-page {
  background-color: #254173;
  min-height: 100%;
}
.logo-large {
  width: 100%;
}

.login-box {
  margin-top: 150px;
}

.spacer{
  margin-top: 50px;
}

body {
  padding: 0;
}