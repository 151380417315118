.single-tag-div {
    padding: 3px 5px;
    margin: 3px 5px;
    border-radius: 5px;
}
.single-tag-text {
    font-size: 0.8em;
    color: white;
    padding-right: 5px;
    margin-bottom: 0px;
}

.link-icon {
  bottom: 0px !important;
}