
.confirmation-text {
  font-size: 1.2em;
}

.status-box {
  font-size: 1.5em;
}

.status-text {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  margin-left: 10px;
  text-shadow: 1px 1px 0px #0000001a;
}

.align-content-right {
  display: flex;
  justify-content: flex-end;
}

.smaller-feedback {
  font-size: 0.85em;
}

.auto-approval-icon-displacement-fix {
  position: relative;
  top: -2px;
}

.auto-approval-icon-displacement-fix:hover {
  cursor: pointer;
}

.popover-for-btn-popover{
  padding: 10px;
}