.rm-pagination-box {
  position: relative;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(0,0,0,0.1);
}

.rm-pagination {
  margin: 20px 20px;
}

.rm-page-selector-box {
  display: flex; 
  align-items: center;
  margin: 0 30px;
}

.rm-page-selector {
  display: inline-block; 
  margin: 0 4px;
  width: 70px; 
  padding: .1rem 1rem; 
  font-size: inherit;
}

.rm-page-size-selector-box {
  /* display: flex; 
  align-items: center; */
  position: absolute;
  right: 0;
  top: 50%;
  height: fit-content;
  transform: translateY(-50%);
  margin: auto 30px;
}

.rm-page-size-selector {
  margin: 0 4px;
  height: 35px !important; 
  font-size: inherit;
}

.rm-pagination .page-item.disabled .page-link {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: inherit !important;
}