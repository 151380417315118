.orange-check .form-check-input {
    border-color: #FFAA3B;
  }
  
  .orange-check .form-check-input:checked {
    background-color: #FFAA3B;
    border-color: #FFAA3B;
  }

  .orange-check .form-check-label {
    color: #FFAA3B;
  }
  
  .orange-check .form-check-input:checked + .form-check-label {
    color: #FFAA3B;
  }

  .yellow-check .form-check-input {
    border-color: #bac700;
  }
  
  .yellow-check .form-check-input:checked {
    background-color: #bac700;
    border-color: #bac700;
  }

  .yellow-check .form-check-label {
    color: #bac700;
  }
  
  .yellow-check .form-check-input:checked + .form-check-label {
    color: #bac700;
  }