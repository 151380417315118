.deleted-card-border {
  border-radius: 5px;
  border-width: 2px;
  border-color: #f45645b0;
  border-style: solid;
}

.deleted-icon {
  margin-right: 5px;
  color: #f4503e;
  font-size: 20px;
}

.new-card-border {
  border-radius: 5px;
  border-width: 2px;
  border-color: #359b35b0;
  border-style: solid;
}

.new-icon {
  margin-right: 5px;
  color: #359b35;
  font-size: 20px;
}

.changed-card-border {
  border-radius: 5px;
  border-width: 2px;
  border-color: #f99c3eb0;
  border-style: solid;
}

.changed-icon {
  margin-right: 5px;
  color: #f99c3e;
  font-size: 20px;
}

.removed-text {
  text-decoration: line-through;
  background-color: #FED8B1;
  padding: 3px;
  margin: 2px;
}

.added-text {
  background-color: #CDFFCD;
  padding: 3px;
  margin: 2px;
}

.cell {
  width: "100%";
  display: "flex";
  margin: 2px;
}