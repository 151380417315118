.user-action-button {
    margin: 0.5rem;
}

.user-action-button.edit-user:hover {
    color: #007bbb;
}

.user-action-button.disable-user:hover {
    color: #ffaa3b;
}

.user-action-button.delete-user:hover {
    color: #aa483e;
}