.days-supply{
    display: flex;
    align-items: center;
}

.days-supply-field {
    height: auto;
}
  
.days-supply-row{
    color: grey solid;
    padding: 0px;
    margin: 0px;
    display: flex;
    width: auto;
    height: auto;
    padding-top: 1.45px;
    padding-bottom: 1.45px;
}

.days-supply-input{
  max-width: 75px;
}

.age-field-title {
  margin-bottom: -0.5rem !important;
}

.age-field{
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem !important;
}