.display-bar {
    background-color: #007BBB; 
    letter-spacing: 1.2px; 
    border: 1px solid #007BBB;
    border-radius: 3px;
    margin-bottom: 16px;
    color: white; 
    text-transform: capitalize;
    padding: 8px 16px; 
    text-align: center;
  }