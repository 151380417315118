/* switch.css */
.switch {
  position: relative;
  display: inline-block;
  width: 100vw;
  max-width: 70px;
  height: auto;
  max-height: 50px;
  aspect-ratio: 2 / 1;
  margin: 0 auto;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #007BBB;
  transition: 0.4s;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider:before {
  position: absolute;
  content: attr(data-off);
  color: #fff;
  font-size: 0.8em;
  text-align: center;
  line-height: 1.2;
  height: 80%;
  width: 50%;
  left: 5%;
  bottom: 10%;
  background-color:#FF783b ;
  transition: 0.4s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
}

input:checked + .slider {
  background-color:#007BBB;
  text-align: center;
}

input:checked + .slider:before {
  transform: translateX(85%);
  content: attr(data-on);
  padding: 0 5%;
}
