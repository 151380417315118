

.bigger-text {
  font-size: 1.5em;
}

.even-bigger-text {
  font-size: 2em;
}
.transition-btn {
  height: 40px;
  margin-top: 21px;
}

.normal-text {
  font-size: 0.7em;
  display: inline-block;
}

.vertical-center{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

div.switch-sec-heading{
  text-transform: uppercase;
  font-size: 1em;
  padding-bottom: 5px;
  padding-left: 10px;
  margin: 5px 0px 20px 0px;
  letter-spacing: 2px;
  border-bottom: 1px solid #ddd;
}

div.medicine-name {
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 1.1em;
  margin: 10px 0;
  font-weight: 700;
}

ul.target-options{
  padding-left: 15px;
}

ul.target-options li {
  font-size: 1em;
  padding: 3px 0;
}


.arrow-right {
  width: 0;
  height: 0;
  border-top: 35px solid transparent;
  border-left: 70px solid #337ab7;
  border-bottom: 35px solid transparent;
  margin: 20px auto;
}

img.arrow {
  margin-top: 10px;
  width: 100%;
}

.spacey {
  letter-spacing: 0.6px;
}

.super-wide-modal {
  width: 90%;
  max-width: none!important;
}

img.pdf-image {
  /* background: url(../../../assets//images/loading-big.gif) 50% no-repeat; */
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
}

.full-width-dd-container > button.btn {
  width: 100%;
}

.held-switch {
  border: 1px solid #db34343b;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}